import Navbar from "../components/NavBar";

import React from 'react'
import MarketingFooter from "../components/MarketingFooter";

const CareersPage = () => {

    return (
        <>
        <div className="bg-primary-dark box-border">
            <Navbar />
        </div>
        <div className="container xl:px-40 mt-10 mb-5 mx-auto">
            <div className="text-center text-2xl pb-2">
                <span className="border-b border-gray-400 px-2 pb-1">
                Working at Kodama    
                </span>
            </div>
            <div className="mt-4 border border-gray-200 p-4 shadow-lg rounded-lg">
                There are no open positions 
            </div>
        </div>
        <div className="absolute bottom-0 w-full">
        <MarketingFooter />
        </div>
        </>
    );
}

export default CareersPage